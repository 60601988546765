/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid } from '@material-ui/core';
import { Flex } from "@theme-ui/components"
import { graphql } from "gatsby"

import { Link } from "gatsby"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import Title from "@lekoarts/gatsby-theme-minimal-blog/src/components/title"
import Listing from "./listing"
import List from "./list"
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config"
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata"
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes"
import { visuallyHidden } from "@lekoarts/gatsby-theme-minimal-blog/src/styles/utils"
import Hero from "@lekoarts/gatsby-theme-minimal-blog/src/texts/hero"
import Bottom from "@lekoarts/gatsby-theme-minimal-blog/src/texts/bottom"

type PostsProps = {
    posts: {
        slug: string
        title: string
        date: string
        excerpt: string
        description: string
        timeToRead?: number
        tags?: {
            name: string
            slug: string
        }[]
    }[]
    [key: string]: any
}


const Homepage = ({ posts }: PostsProps) => {
    const { basePath, blogPath } = useMinimalBlogConfig()
    const { siteTitle } = useSiteMetadata()
    const showTags = true;
    return (
        <Layout>
            {/* <h1 sx={visuallyHidden}>{siteTitle}</h1> */}
            {/* <Grid gap={2} columns={[2, '1fr 2fr']}> */}
            <Grid container>
                <section sx={{ mb: [2, 2, 3], p: { fontSize: [1, 2, 3], mt: 1, }, variant: `section_hero` }}>
                    <Hero />
                </section>
                {/* <Grid item xs={12} sm={4} >
                    <section sx={{ mb: [2, 2, 3], p: { fontSize: [1, 2, 3], mt: 1, }, variant: `section_hero` }}>
                        <Hero />
                    </section>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <KnowledgeGraph posts={posts} />
                </Grid> */}
            </Grid>
            {/* <Title text=""> */}
                {/* <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>Read all posts</Link> */}
            {/* </Title> */}
            {/* <List sx={{ variant: `section_bottom` }}> */}
            <Flex sx={{ alignItems: `center`, justifyContent: `space-between`, flexFlow: `wrap` }}>
            <Listing posts={posts} showTags={true}  />
            </Flex>
            <Bottom />
            {/* </List> */}
        </Layout >
    )
}

export default Homepage
